// Here you can add other styles
.social__accounts {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  &-error-message {
    margin-bottom: 10px;
    color: red;
  }

  .google {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #deefff;
    cursor: pointer;
    border: none;

    img {
      width: 20px;
      height: 20px;
    }

    h6 {
      margin-bottom: 0;
      margin: 0;
      font-size: 14px;
      margin-left: 10px;
      display: flex;
      align-items: center;

      svg {
        width: 20px;
      }
    }
  }
}

.user-item-drawer {
  width: 450px;
  padding: 20px;
}

.chapter-progress {

  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    span {
      font-size: 12px;
    }
  }

  &-bar {
    height: 100%;
    background-color: #32a912;
    border-radius: 6px;

    &-holder {
      width: 100%;
      height: 4px;
      background: lightgrey;
      border-radius: 6px;
    }
  }
}
